import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddValidityPerionModal = _resolveComponent("AddValidityPerionModal")!
  const _component_AddDirectoryModal = _resolveComponent("AddDirectoryModal")!
  const _component_AddTrafficAmountModal = _resolveComponent("AddTrafficAmountModal")!
  const _component_AddDirectoryZones = _resolveComponent("AddDirectoryZones")!
  const _component_AddDirectoryServers = _resolveComponent("AddDirectoryServers")!
  const _component_AddPlanModal = _resolveComponent("AddPlanModal")!
  const _component_AddEsimTypeModal = _resolveComponent("AddEsimTypeModal")!
  const _component_DeleteConfirmDialog = _resolveComponent("DeleteConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AddValidityPerionModal, {
      opened: _ctx.modals.validityPeriodModal,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      "modal-title": _ctx.modalTitle,
      "localization-list": _ctx.localizationList,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler,
      onSaveLocalization: _ctx.saveLocalizationHandler
    }, null, 8, ["opened", "edit-data", "modal-title", "localization-list", "onSubmit", "onHide", "onSaveLocalization"]),
    _createVNode(_component_AddDirectoryModal, {
      "modal-title": _ctx.modalTitle,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      type: _ctx.type,
      opened: _ctx.modals.directoryModal,
      "localization-list": _ctx.localizationList,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler,
      onSaveLocalization: _ctx.saveLocalizationHandler
    }, null, 8, ["modal-title", "edit-data", "type", "opened", "localization-list", "onSubmit", "onHide", "onSaveLocalization"]),
    _createVNode(_component_AddTrafficAmountModal, {
      "modal-title": _ctx.modalTitle,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      type: _ctx.type,
      opened: _ctx.modals.trafficAmountModal,
      "localization-list": _ctx.localizationList,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler,
      onSaveLocalization: _ctx.saveLocalizationHandler
    }, null, 8, ["modal-title", "edit-data", "type", "opened", "localization-list", "onSubmit", "onHide", "onSaveLocalization"]),
    _createVNode(_component_AddDirectoryZones, {
      "modal-title": _ctx.modalTitle,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      type: _ctx.type,
      opened: _ctx.modals.esimZonesModal,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler
    }, null, 8, ["modal-title", "edit-data", "type", "opened", "onSubmit", "onHide"]),
    _createVNode(_component_AddDirectoryServers, {
      "modal-title": _ctx.modalTitle,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      type: _ctx.type,
      opened: _ctx.modals.esimServersModal,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler
    }, null, 8, ["modal-title", "edit-data", "type", "opened", "onSubmit", "onHide"]),
    _createVNode(_component_AddPlanModal, {
      opened: _ctx.modals.plansModal,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      "upload-handler": _ctx.uploadImageHandler,
      "modal-title": _ctx.modalTitle,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler
    }, null, 8, ["opened", "edit-data", "upload-handler", "modal-title", "onSubmit", "onHide"]),
    _createVNode(_component_AddEsimTypeModal, {
      "esim-servers-free": _ctx.esimServersFree,
      "esim-servers-all": _ctx.esimServersAll,
      "edit-data": _ctx.id ? _ctx.newEditData : null,
      "data-types": _ctx.dataTypes,
      "modal-title": _ctx.modalTitle,
      opened: _ctx.modals.esimTypesModal,
      onSubmit: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler
    }, null, 8, ["esim-servers-free", "esim-servers-all", "edit-data", "data-types", "modal-title", "opened", "onSubmit", "onHide"]),
    _createVNode(_component_DeleteConfirmDialog, {
      opened: _ctx.modals.deleteModal,
      onCancel: _ctx.cleanDataHandler,
      onConfirm: _ctx.submitHandler,
      onHide: _ctx.cleanDataHandler
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, [
            _createElementVNode("b", null, "Are you sure want to remove this item of \"" + _toDisplayString(_ctx.title) + "\"", 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["opened", "onCancel", "onConfirm", "onHide"])
  ], 64))
}