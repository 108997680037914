import { ref } from "@vue/runtime-core";
import { Ref } from "vue";
import { useStore } from "vuex";
import useLocalizations from "@/hooks/localizations/useLocalizations";
import { ILocalizations } from "@/api/interfaces/localizations/ILocalizations";
import {
  IFetchDirectoryLocalizationData,
  IMappedDirectoryLocalization
} from "../types/esimDirectoryLocalizations.types";
import { TEsimDirectoryTypes } from "../types/esimDirectories.types";

export default function useEsimDirectoryLocalizations() {
  const store = useStore();
  const localizationList: Ref<IMappedDirectoryLocalization[]> = ref([]);
  const { localizations } = useLocalizations();

  const fetchDirectoryLocalization = async (
    directoryRecordId: number,
    type: TEsimDirectoryTypes
  ) => {
    const { data } = await store.dispatch("fetchDirectoryLocalizations", {
      directoryRecordId,
      type
    });
    return data;
  };

  const mapDirectory = (
    directoryLocalization: IFetchDirectoryLocalizationData[],
    id: number
  ): IMappedDirectoryLocalization[] => {
    return localizations.value.map((localization: ILocalizations) => {
      const matchingLocalization = directoryLocalization?.find(
        (localizationItem: any) =>
          localizationItem.localization?.id === localization?.id
      );
      const title = matchingLocalization ? matchingLocalization.title : "";
      return {
        ...localization,
        id: matchingLocalization?.id || null,
        localizationId: localization?.id,
        title,
        directoryRecordId: id || -1
      };
    });
  };

  const startEditing = async (id: number, type: TEsimDirectoryTypes) => {
    const typesList = [
      "validityPeriod",
      "purchaseType",
      "dataTypes",
      "trafficAmount"
    ];
    if (!typesList.includes(type)) return;

    const directoryLocalization: IFetchDirectoryLocalizationData[] = await fetchDirectoryLocalization(
      id,
      type
    );
    localizationList.value = mapDirectory(directoryLocalization, id);
  };

  const saveLocalization = async (
    localization: IMappedDirectoryLocalization,
    type: TEsimDirectoryTypes
  ): Promise<boolean> => {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch(
      "editDirectoryLocalization",
      {
        editData: localization,
        type
      }
    );

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    cleanLocalizationData();
    await store.dispatch("setAppLoading", false);
    return success;
  };

  const cleanLocalizationData = () => {
    localizationList.value = [];
  };

  return {
    localizations,
    localizationList,
    startEditing,
    saveLocalization,
    cleanLocalizationData
  };
}
