import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_TabsDialog = _resolveComponent("TabsDialog")!

  return (_openBlock(), _createBlock(_component_TabsDialog, _mergeProps({ key: 1 }, _ctx.$props, {
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedTab) = $event)),
    tabs: _ctx.tabs,
    title: _ctx.modalTitle,
    opened: _ctx.opened,
    class: "create-location-modal",
    onHide: _ctx.cleanData
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("form", {
          class: "pt-3",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _createVNode(_component_AppInputsValidationProvider, {
            "shake-tree": _ctx.parentTouched,
            onChangeErrorState: _ctx.onChangeErrorState
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_AppInput, {
                  id: "new-network-title",
                  modelValue: _ctx.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
                  "validation-type": "name",
                  "input-type": "material",
                  type: "text",
                  name: "new-network-title",
                  label: "Title"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_AppInput, {
                  id: "new-network-key",
                  modelValue: _ctx.amount,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.amount) = $event)),
                  type: "number",
                  name: "new-network-title",
                  "validation-type": "name",
                  "input-type": "material",
                  label: "Megabytes"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }, 8, ["shake-tree", "onChangeErrorState"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppButton, {
              size: "xl",
              type: "secondary",
              disabled: !_ctx.valid,
              onClick: _ctx.submitHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ], 32)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localizationList, (localization) => {
        return (_openBlock(), _createElementBlock("div", {
          key: localization.localizationId
        }, [
          _createElementVNode("form", {
            class: "pt-3",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_AppInput, {
              id: "new-validity-period-title",
              modelValue: localization.title,
              "onUpdate:modelValue": ($event: any) => ((localization.title) = $event),
              "input-type": "material",
              "validation-type": "name",
              type: "text",
              label: "Title"
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppButton, {
                size: "xl",
                disabled: !localization.title,
                type: "secondary",
                onClick: ($event: any) => (_ctx.saveLocalization(localization))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Save localization ")
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"])
            ])
          ], 32)
        ]))
      }), 128))
    ]),
    _: 1
  }, 16, ["modelValue", "tabs", "title", "opened", "onHide"]))
}