import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_CountrySelect = _resolveComponent("CountrySelect")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps({ title: _ctx.modalTitle }, _ctx.$props, { onHide: _ctx.cleanData }), {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_AppInput, {
          id: "new-network-title",
          modelValue: _ctx.title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
          "input-type": "material",
          label: "Title"
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_CountrySelect, {
            list: _ctx.getDataTypes,
            checked: _ctx.getDataTypes,
            type: "user",
            class: "position-relative",
            placeholder: "Chose data types",
            "on-change": _ctx.selectDataTypeHandler
          }, null, 8, ["list", "checked", "on-change"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CountrySelect, {
            list: _ctx.getServers,
            checked: _ctx.getServers,
            type: "user",
            disabled: !_ctx.getServers.length,
            class: "position-relative",
            placeholder: "Chose servers",
            "on-change": _ctx.selectEsimServerHandler
          }, null, 8, ["list", "checked", "disabled", "on-change"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppButton, {
            size: "xl",
            type: "secondary",
            onClick: _ctx.submitHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 16, ["title", "onHide"]))
}