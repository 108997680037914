import { ComputedRef } from "vue";
import { sortOrders } from "./../../../../enums/main/sortOrders";
export const getEsimDirectorySortConfig = (
  currentSortKeyDirectoryName: ComputedRef<string>
) => ({
  byTitle: {
    order: sortOrders.asc,
    keyName: currentSortKeyDirectoryName.value,
    keyNameInModel: "title"
  },
  byStatus: {
    order: sortOrders.turnOff,
    keyName: "status",
    keyNameInModel: "status"
  }
});
