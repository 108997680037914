
import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import { defineComponent, PropType } from "vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import { IMappedDirectoryLocalization } from "@/hooks/esim/directories/types/esimDirectoryLocalizations.types";
const initialData = () => ({
  active: false,
  title: "",
  key: "",
  parentTouched: false,
  valid: false,
  selectedTab: 0
});
export default defineComponent({
  name: "AddDirectoryModal",
  components: {
    AppInput,
    AppCheckbox,
    TabsDialog,
    AppButton,
    AppInputsValidationProvider
  },
  props: {
    editData: {
      type: Object as PropType<IEsimDirectoryDefault | null>,
      required: true
    },
    modalTitle: { type: String, required: true },
    type: { type: String, default: "" },
    opened: { type: Boolean, default: false },
    localizationList: {
      type: Array as PropType<IMappedDirectoryLocalization[]>,
      default: () => []
    }
  },
  emits: {
    submit: null,
    hide: null,
    saveLocalization: null
  },
  data() {
    return initialData();
  },
  computed: {
    isNetworkGenerationModal() {
      return this.type === "networkGeneration";
    },
    tabs() {
      const details = {
        title: "Details",
        id: 0
      };
      if (this.isNetworkGenerationModal) return [details];

      return [
        details,
        ...this.localizationList.map((el: any) => ({
          title: el.short_name,
          id: el.localizationId
        }))
      ];
    }
  },
  watch: {
    opened() {
      this.selectedTab = 0;
    },
    editData: {
      handler(data: any) {
        if (!data) {
          Object.assign(this.$data, initialData());
        } else {
          Object.assign(this.$data, data);
        }
      },
      immediate: true
    }
  },
  methods: {
    cleanData() {
      Object.assign(this.$data, initialData());
      this.$emit("hide");
    },
    submitHandler() {
      this.parentTouched = true;
      const { active, title, key } = this;
      this.cleanData();
      this.$emit("submit", { active, title, key });
      this.cleanData();
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    },
    saveLocalization(localization: IMappedDirectoryLocalization) {
      this.$emit("saveLocalization", localization);
      this.cleanData();
    }
  }
});
