import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps({ title: _ctx.modalTitle }, _ctx.$props, { onHide: _ctx.cleanData }), {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_AppInputsValidationProvider, {
          "shake-tree": _ctx.parentTouched,
          onChangeErrorState: _ctx.onChangeErrorState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              id: "new-network-title",
              modelValue: _ctx.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
              "input-type": "material",
              type: "text",
              "validation-type": "name",
              label: "Title"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AppInput, {
                id: "new-network-key",
                modelValue: _ctx.key,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.key) = $event)),
                "input-type": "material",
                type: "text",
                "validation-type": "name",
                label: "Key"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["shake-tree", "onChangeErrorState"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppButton, {
            size: "xl",
            type: "secondary",
            disabled: !_ctx.valid,
            onClick: _ctx.submitHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 16, ["title", "onHide"]))
}