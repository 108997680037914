import { IEsimDirectoryDefault } from "./../../../../api/interfaces/esim-directory/common";
export const esimDirectoriesList = (directoryData: IEsimDirectoryDefault[]) => {
  return directoryData.map(directory => {
    const { active, id, title, name } = directory;
    return {
      id,
      title: title ?? name,
      status: active
    };
  });
};
